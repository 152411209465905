import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';

import './index.css';
import Pix2PixGuideImplementation from './pages/posts/Pix2Pix-Guide-Implementation';

import Header from './components/Header';
import Aboutme from './pages/Aboutme';
import Scraps from './pages/Scraps';
import LinearClass from './pages/posts/FullyLinearClassificationModel';
import CNNClassificationModel from './pages/posts/CNNClassificationModel';
import SegmentationRUNET from './pages/posts/SegmentationRUNET';
import SuperResolution from './pages/posts/SuperResolution';
import VQGAN from './pages/posts/VQGAN';
import HDCatGeneration from './pages/posts/HDCatGeneration';
import NeRFFromScratch from './pages/posts/NeRFFromScratch';
import DALLE1 from './pages/posts/DALLE1';

import Redirect from './pages/Redirect';

import Repos from './pages/Repos';

import EmptyRepo from './pages/repos/EmptyRepo';
import ResearchContributions from './pages/repos/ResearchContributions';
import Fluids from './pages/posts/Fluids';
import Docker from './pages/posts/Docker';
import YOLO from './pages/posts/Fraud';

import Shark from './pages/shark/Shark';
import GreenChange from './pages/posts/GreenChange';

const root = ReactDOM.createRoot(document.getElementById('root'));
// ANTES ERA PX 8 no sem md: or lg: sm:
const App = () => {
  return (
    <React.StrictMode>
      <HashRouter basename="/">
        <Header />
        <div className="mx-auto max-w-4xl lg:max-w-5xl pt-20 px-4 lg:px-14 sm:px-max-w-3xl md:px-14"> 
          <Routes>
            <Route path="/" element={<Scraps />} />

            <Route path="/about" element={<Aboutme />} />
            <Route path="/notes" element={<Scraps />} />
            <Route path="/repos" element={<Repos />} />

            <Route path="/notes/Developing-a-Super-Resolution-Image-Upscaling-Technique-from-Scratch-Using-PyTorch" element={<SuperResolution />} />
            <Route path="/notes/State-of-the-Art-Latent-Space-Quantization-with-VQ-GANs-from-Scratch-Using-Pytorch" element={<VQGAN />} />
            <Route path="/notes/Building-a-NeRF-Technique-for-Synthetic-Scene-Reconstruction-from-Scratch-Using-Pytorch" element={<NeRFFromScratch />} />
            <Route path="/Notes/Transformer-Powered-Non-Conditional-Latent-Image-Generation-from-Scratch-within-PyTorch" element={<DALLE1 />} />
            <Route path="/Notes/Implementing-Simple-Smooth-Particle-Hydrodynamics-Simulations-from-Scratch-in-Python" element={<Fluids />} />
            <Route path="/Notes/Analyzing-Satellite-Data-From-Google-Earth-Engine-to-Track-Urban-Green-Area-Changes-in-Portugal" element={<GreenChange />} />

          </Routes> 
        </div>
      </HashRouter>
    </React.StrictMode>
  );
};

root.render(
  <App />
);
