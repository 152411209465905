import React from 'react';

const GreenChange = () => {
  return (
    <div>
      <h2>GreenChange</h2>

      <iframe
        src={`${process.env.PUBLIC_URL}/Analyzing-Satellite-Data-From-Google-Earth-Engine-to-Track-Urban-Green-Area-Changes-in-Portugal-I/fires_map.html`}
        width="100%"
        height="500px"
        style={{ border: 'none' }}
        title="Map"
      ></iframe>
      
    </div>
  );
};

export default GreenChange;
