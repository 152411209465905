import React, { useState } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/vs2015.css';
import 'tailwindcss/tailwind.css'; 

const CodeBlock = ({ code }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');

  const codeWithSpaces = code.split('\n').map(line => line + '  ').join('\n');
  const highlightedCode = hljs.highlight('python', codeWithSpaces).value;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(code)
      .then(() => {
        setCopySuccess('Code Copied!');
        setTimeout(() => setCopySuccess(''), 2000); // Clear the success message after 2 seconds
      })
      .catch(err => {
        setCopySuccess('Failed to copy!');
      });
  };

  return (
    <div 
      className="relative overflow-x-auto" 
      onMouseEnter={() => setIsHovered(true)} 
      onMouseLeave={() => setIsHovered(false)}
    >
      <pre
        className="bg-gray-900 p-4 rounded-md overflow-y-auto"
        style={{
          paddingRight: '1em',
          boxSizing: 'border-box',
        }}
      >
        <code 
          className="text-white text-xs font-mono block code-select"
          dangerouslySetInnerHTML={{ __html: highlightedCode }}
        />
      </pre>

      {isHovered && (
        <button 
          onClick={handleCopyClick}
          className="hidden sm:hidden custom-font absolute top-0 right-0 mt-2 mr-2 px-2 py-1  text-white text-sm rounded hover:underline"
        >
          {copySuccess || 'Copy Code'}
        </button>
      )}

      <style jsx>{`
        /* For WebKit browsers */
        ::-webkit-scrollbar {
          width: 12px;
        }
        
        /* Track */
        ::-webkit-scrollbar-track {
          background: #f1f1f1;
        }
        
        /* Handle */
        ::-webkit-scrollbar-thumb {
          background: #888;
        }
        
        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #555;
        }
      `}</style>
    </div>
  );
};

export default CodeBlock;
